import React from 'react';
import '../App.css';
import { slide as Menu } from 'react-burger-menu';
import logo from '../img/logo.png'
// import { StickyContainer, Sticky } from 'react-sticky';
// import Journey from "../pages/Journey";
// import JourneyImage from "./JourneyImage";

// export default function Nav() {
//     const nameStyle={
//         paddingTop:"8vh",
//         fontFamily:"中国式手写风",
//         fontSize:"15pt",
//         paddingBottom:"1vh"
//
//     }
//     const nameStyle2={
//         paddingTop:"2vh",
//         fontFamily:"中国式手写风",
//         fontSize:"15pt",
//         paddingBottom:"1vh",
//         backgroundColor:"white"
//     }
//
//     const navStyle={
//         minWidth:"10vw",
//         listStyle:"none",
//         paddingTop: "15pt",
//         paddingBottom:"18pt",
//         fontSize: "10pt",
//         margin:0,
//
//     }
//     const navStyle2={
//         minWidth:"10vw",
//         listStyle:"none",
//         paddingBottom:"18pt",
//         fontSize: "10pt",
//         margin:0,
//         backgroundColor:"white"
//     }
//
//     const liStyle={
//         display:"inline",
//         paddingRight:"30pt",
//         lineHeight:"10pt"
//     }
//     const table={
//         display:"table",
//         margin:0,
//         width:"100vw",
//
//     }
//     return(
//         <div>
//             <StickyContainer>
//                 <header>
//                     <div style={nameStyle}>
//                         BRANDON LAI
//                     </div>
//                     <div style={table}>
//                         <ul style={navStyle}>
//                             <a href={"/"}>
//                                 <li style={liStyle}>environment</li>
//                             </a>
//                             <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
//                             <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
//                             <a href={"/about"}><li style={liStyle}>about</li></a>
//                             <a href={"/contact"}><li style={liStyle}>contact</li></a>
//                         </ul>
//                     </div>
//                 </header>
//                 <JourneyImage/>
//                 <Sticky topOffset={80}>
//                 {({
//                       style,
//                   }) => (
//                     <header style={style}>
//                         <div style={nameStyle2}>
//                             BRANDON LAI
//                         </div>
//                         <div style={table}>
//                             <ul style={navStyle2}>
//                                 <a href={"/"}>
//                                     <li style={liStyle}>environment</li>
//                                 </a>
//                                 <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
//                                 <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
//                                 <a href={"/about"}><li style={liStyle}>about</li></a>
//                                 <a href={"/contact"}><li style={liStyle}>contact</li></a>
//                             </ul>
//                         </div>
//                     </header>
//                 )}
//                 </Sticky>
//             </StickyContainer>
//
//         </div>)
// }
export default class Nav extends React.Component{
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

// make sure to remove the listener
// when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };
    showSettings (event) {
        event.preventDefault();

    }

    render(){
    const nameStyle={
        objectFit:"cover",
        paddingBottom:"0.1vh",
        display:"block",
        marginLeft:"auto",
        marginRight:"auto",
        paddingTop:"3%"
    }

    let liStyle={
        paddingInline:"5pt",
        fontFamily:"华文细黑",
        display:"inline",
    }
    const table={
        display:"table",
        margin:0,
        width:"100vw",

    }
    const emailStyle={
        fontFamily:"华文细黑",
        paddingTop:"5pt",
        display:"inline",
        lineHeight:"10pt"
    }
        const { width } = this.state;
        const isMobile = width <= 768;
        const isMiddleScreen = width <= 1366;
        if (isMobile){ let imageStyle={width:"50%",display:"block",
            marginRight:"auto",objectFit:"cover",paddingTop:"5%",paddingLeft:"12%",marginLeft: 0,
            paddingBottom:"0",}
        return (
            <div >
                <Menu right >
                    <div style={{paddingTop:"40%",overflowX:"hidden"}}></div>
                        <a href={"/works/lego"} style={{color:"white",padding:"0.8em",display:"block"}}>Lego Monkie kid</a>
                        <a href={"/works/environment"}style={{color:"white",padding:"0.8em",display:"block"}}>environment
                        </a>
                        <a href={"/works/illustration"} style={{color:"white",padding:"0.8em",display:"block"}}>illustration</a>
                        <a href={"/works/sketch"} style={{color:"white",padding:"0.8em",display:"block"}}>sketch</a>
                        <a href={"/about"} style={{color:"white",padding:"0.8em",display:"block"}}>about</a>
                </Menu>
                <a href={"/"}><img id="page-wrap" style={imageStyle} src={logo}  alt={"Logo"}>
                </img></a>
                <div id="page-wrap" style={{paddingLeft:"12%",paddingRight:"auto", fontFamily:"华文细黑",display:"block",width:"50%",marginTop:0,marginLeft:0,marginRight:"auto",paddingTop:"3%",textAlign:"left"}}>orenji002@gmail.com</div>

            </div>
        );}
        let navStyle2={
            fontSize: "11pt",}
        if (isMiddleScreen){

                return (
            <div>
            <a href={"/"}><img src={logo} style={nameStyle} alt={"Logo"}>
            </img></a>
                <div style={emailStyle}>
                    orenji002@gmail.com</div>
            <div style={table}>
                <ul style={navStyle2}>
                    <a href={"/works/lego"}><li style={liStyle}>Lego Monkie kid</li></a>
                    <a href={"/works/environment"}>
                        <li style={liStyle}>environment</li>
                    </a>
                    <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
                    <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
                    <a href={"/about"}><li style={liStyle}>about</li></a>
                </ul>
            </div>
        </div>);}
        else{
            let liStyle={
                paddingInline:"1em",
                fontFamily:"华文细黑",
                display:"inline",}

            let navStyle={
                minWidth:"10vw",
                listStyle:"none",
                paddingTop: "5pt",
                paddingBottom:"18pt",
                fontSize: "15pt",
                margin:0,
                backgroundColor:"white"
            }
    return(
        <div>
            <a href={"/"}><img src={logo} style={nameStyle} alt="logo" border="0"/></a>
            <div style={emailStyle}>
                orenji002@gmail.com</div>
            <div style={table}>
                <ul style={navStyle}>
                    <a href={"/works/lego"}><li style={liStyle}>Lego Monkie kid</li></a>
                    <a href={"/works/environment"}>
                        <li style={liStyle}>environment</li>
                        </a>
                    <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
                    <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
                    <a href={"/about"}><li style={liStyle}>about</li></a>
                </ul>
            </div>
        </div> );}
    }}
// const nameStyle={
//     paddingTop:"8vh",
//     fontFamily:"中国式手写风",
//     fontSize:"15pt",
//     paddingBottom:"1vh"
//
// }
//
// const navStyle={
//     minWidth:"10vw",
//     listStyle:"none",
//     paddingTop: "5pt",
//     paddingBottom:"18pt",
//     fontSize: "10pt",
//     margin:0,
//
// }

// const liStyle={
//     display:"inline",
//     paddingRight:"30pt",
//     lineHeight:"10pt"
// }
// const table={
//     display:"table",
//     margin:0,
//     width:"100vw",
//
// }
// return(
//     <div>
//         <div style={nameStyle}>
//             BRANDON LAI
//         </div>
//         <div style={table}>
//             <ul style={navStyle}>
//                 <a href={"/"}>
//                     <li style={liStyle}>WORK</li>
//                 </a>
//                 <a href={"/about"}><li style={liStyle}>ABOUT</li></a>
//                 <a href={"/contact"}><li style={liStyle}>CONTACT</li></a>
//             </ul>
//         </div>
//     </div>)
//     const nameStyle={
//         padding:"3% 3% 3%",
//         position:"fixed",
//         fontFamily:"中国式手写风",
//         fontSize:"15pt",
//         paddingBottom:"1vh"
//
//     }
//
//     const navStyle={
//         minWidth:"10vw",
//         listStyle:"none",
//         paddingTop: "100pt",
//         paddingLeft:"3%",
//         fontSize: "10pt",
//         textAlign:"left",
//         position:"fixed",
//         textDecoration:"none"
//
//     }
//
//     const liStyle={
//         paddingBottom:"15pt",
//         lineHeight:"10pt"
//     }
//     const table={
//         display:"table",
//         margin:0,
//         width:"100vw",
//
//     }
//     return(
//         <div>
//             <div style={nameStyle}>
//                 BRANDON LAI
//             </div>
//             <div style={table}>
//                 <ul style={navStyle}>
//                     <a href={"/"}>
//                         <li style={liStyle}>Environment</li>
//                     </a>
//                     <a href={"/works/illustration"}><li style={liStyle}>Illustration</li></a>
//                     <a href={"/works/sketch"}><li style={liStyle}>Sketch</li></a>
//                     <a href={"/about"}><li style={liStyle}>ABOUT</li></a>
//                     <a href={"/contact"}><li style={liStyle}>CONTACT</li></a>
//                 </ul>
//             </div>
//         </div>
//     )
// }