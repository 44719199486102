import React from 'react';
import '../App.css';
import Nav from "../component/Nav";
import {Sticky, StickyContainer} from "react-sticky";
import LegoImage from "../component/LegoImage";
export default class Lego extends React.Component{
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

// make sure to remove the listener
// when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };
    render(){
        const table={
            display:"table",
            margin:0,
            width:"100vw",}

        const emailStyle={
            fontFamily:"华文细黑",
            display:"inline",
            lineHeight:"10pt",
        }


        const { width } = this.state;
        const isMiddleScreen = width <= 1366;
        const isMobile = width <= 768;
        if (isMobile){
            let liStyle={
                fontFamily:"华文细黑",
                color:"#4d4d4d",
                display:"inline",
                paddingInline:"5pt",
            }
            let navStyle2={
                minWidth:"10vw",
                listStyle:"none",
                paddingTop:"10pt",
                paddingBottom:"13pt",
                fontSize: "10pt",
                margin:0,
                paddingLeft:0
            }


            // return(
            // <section>
            //     <section style={{float:"left",width:"10%"}}><Nav/></section>
            //     <IllustrationImage style={{float:"right",width:"90%"}}/>
            //     <div style={{fontSize:"8pt",paddingTop:"10pt",paddingBottom:"10pt"}}>© 2020 Brandon Lai. All rights reserved.</div>
            // </section>


            return(
                <section style={{height:"auto"}}>
                    <Nav/>
                    {/*<StickyContainer>*/}
                        <LegoImage/>
                        <div style={{fontSize:"8pt",paddingTop:"6%"}}>© 2020 Brandon Lai. All rights reserved.</div>

                </section>
            );}
        if (isMiddleScreen){let navStyle3={
            minWidth:"10vw",
            listStyle:"none",
            paddingTop:"8pt",
            paddingBottom:"13pt",
            fontSize: "11pt",
            margin:0}

            let liStyle={
                color:"#4d4d4d",
                display:"inline",
                paddingInline:"5pt",
            }
            return (
                <section style={{height:"auto"}}>
                    <Nav/>
                    <StickyContainer>
                        <LegoImage/>
                        <div className={"arrow"} style={{padding:"3% 10% 3% 3%", textAlign:"right"}}>
                            <a className="animated-arrow" href="/works/environment">
                    <span className="the-arrow -left">
                        <span className="shaft"></span>
                    </span>
                                <span className="main">
                        <span className="text">
                            Environment
                        </span>
                        <span className="the-arrow -right">
                            <span className="shaft"></span>
                        </span>
                    </span>
                            </a>
                        </div>
                        <div style={{fontSize:"8pt"}}>© 2020 Brandon Lai. All rights reserved.</div>
                        <Sticky topOffset={10}>
                            {({
                                  style,
                              }) => (
                                <header style={style}>
                                    <div style={{backgroundColor:"white",paddingTop:"15pt"}}>
                                        <div style={emailStyle}>
                                            orenji002@gmail.com</div>
                                        <div style={table}>
                                            <ul style={navStyle3}>
                                                <a href={"/works/lego"}><li style={liStyle}>Lego Monkie Kid</li></a>
                                                <a href={"/works/environment"}>
                                                    <li style={liStyle}>environment</li>
                                                </a>
                                                <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
                                                <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
                                                <a href={"/about"}><li style={liStyle}>about</li></a>
                                            </ul>
                                        </div>
                                    </div>
                                </header>
                            )}
                        </Sticky>
                    </StickyContainer>
                </section>);}
        else{
            let navStyle2={
                minWidth:"10vw",
                listStyle:"none",
                paddingTop:"10pt",
                paddingBottom:"13pt",
                fontSize: "15pt",
                margin:0,
            }
            const liStyle={
                color:"#4d4d4d",
                display:"inline",
                paddingInline: "1em",
            }
            const table={
                display:"table",
                margin:0,
                width:"100vw",}
            return(
                <section style={{height:"auto"}}>
                    <Nav/>
                    <StickyContainer>
                        <LegoImage/>
                        {/*<img style={{paddingTop:"5pt"}} className={"imageStylePage"} src={require("../img/wukong.png")} alt={"Journey"}/>*/}
                        {/*<img className={"imageStylePage"} src={require("../img/Journey 01.png")} alt={"Journey"}/>*/}
                        {/*<img className={"imageStylePage"} src={require("../img/Journey 02.png")} alt={"Journey"}/>*/}
                        {/*<img className={"imageStylePage"} src={require("../img/snow.png")} alt={"Journey"}/>*/}
                        <div className={"arrow"} style={{padding:"3% 10% 3% 3%", textAlign:"right"}}>
                            <a className="animated-arrow" href="/works/environment">
                    <span className="the-arrow -left">
                        <span className="shaft"></span>
                    </span>
                                <span className="main">
                        <span className="text">
                            Environment
                        </span>
                        <span className="the-arrow -right">
                            <span className="shaft"></span>
                        </span>
                    </span>
                            </a>
                        </div>
                        <div style={{fontSize:"8pt"}}>© 2020 Brandon Lai. Website designed by Vivi Chen. All rights reserved.</div>
                        <Sticky topOffset={10}>
                            {({
                                  style,
                              }) => (
                                <header style={style}>
                                    <div style={{backgroundColor:"white",paddingTop:"15pt"}}>
                                        <div style={emailStyle}>
                                            orenji002@gmail.com</div>
                                        <div style={table}>
                                            <ul style={navStyle2}>
                                                <a href={"/works/lego"}><li style={liStyle}>Lego Monkie Kid</li></a>
                                                <a href={"/works/environment"}>
                                                    <li style={liStyle}>environment</li>
                                                </a>
                                                <a href={"/works/illustration"}><li style={liStyle}>illustration</li></a>
                                                <a href={"/works/sketch"}><li style={liStyle}>sketch</li></a>
                                                <a href={"/about"}><li style={liStyle}>about</li></a>
                                            </ul>
                                        </div>
                                    </div>
                                </header>
                            )}
                        </Sticky>
                    </StickyContainer>
                </section>
            );}
    }}
