import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import FsLightbox from 'fslightbox-react';



const images = [
    {
        src:require("../img/ChangEFactoryESTA0001_design_clean_v002.png"),
        width: 3320,
        height: 1920
    },
    {
        src:require("../img/LanternCityTheatreESTA0004_design_clean_v001.png"),
        width: 1920,
        height: 2484
    },

    {
        src:require("../img/MountainPeakFlashbackA0007_design_clean_v002.png"),
        width: 3412,
        height: 1920
    },
    {
        src:require("../img/DBKFoundryWorkshopA0009_design_clean_v002.png"),
        width: 3431,
        height: 1920
    },
    {
        src:require("../img/DBKFoundryEXTA0005b_design_clean_v001.png"),
        width: 2267,
        height: 1920
    },
    {
        src:require("../img/DBKFoundryDiningHallA0024_design_clean_v002.png"),
        width: 2789,
        height: 1920
    },
    {
        src:require("../img/DBKFoundryDiningHallA0014_design_clean_v002.png"),
        width: 3366,
        height: 1920
    },
    {
        src:require("../img/GiantLanternINTST01A0014_design_clean_v002.png"),
        width: 2868,
        height: 1920
    },
    {
        src:require("../img/GiantLanternINTA0036_design_clean_v002.png"),
        width: 3169,
        height: 1920
    },
    {
        src:require("../img/GiantLanternEXTA0003_design_clean_v002.png"),
        width: 1911,
        height: 1920
    },
    {
        src:require("../img/MountainPeakDestroyedST02EXTA0008_design_clean_v002.png"),
        width: 2954,
        height: 1920
    },
    {
        src:require("../img/MountainPeakDestroyedST01EXTA0008_design_clean_v002.png"),
        width: 3954,
        height: 1920
    },

    {
        src:require("../img/LanternCityMarketEXTA0007_design_clean_v003.png"),
        width: 3413,
        height: 1920
    },
    {
        src:require("../img/LanternCityMarketEXTA0005_design_clean_v001.png"),
        width: 3270,
        height: 1920
    },
    {
        src:require("../img/LanternCityMarketEXTA0001_design_clean_v001.png"),
        width: 3413,
        height: 1920
    },
    {
        src:require("../img/LadyBoneDemonTowerEXTA0035_design_clean_v002.png"),
        width: 3120,
        height: 1920
    },

    {
        src:require("../img/DOEPalaceEXTA0035_design_clean_v003.png"),
        width: 3249,
        height: 1920
    },
    {
        src:require("../img/DOEPalaceEXTA0026_design_clean_v001.png"),
        width: 3416,
        height: 1920
    },
    {
        src:require("../img/DesertOasisShackA0039_design_clean_v003.png"),
        width: 3412,
        height: 1920
    },

    {
        src:require("../img/ChangEKitchenINTA0014_design_clean_v003.png"),
        width: 2752,
        height: 1920
    },
    {
        src:require("../img/ChangEKitchenINTA0012_design_clean_v003.png"),
        width: 2631,
        height: 1920
    },
    {
        src:require("../img/ChangEKitchenINTA0008_design_clean_v002.png"),
        width: 3249,
        height: 1920
    },
    {
        src:require("../img/ChangEFactoryINTA0003_design_clean_v003.png"),
        width: 3319,
        height: 1920
    },

    {
        src:require("../img/ChangEFactoryINTA0002_design_clean_v001.png"),
        width: 2933,
        height: 1920
    },
    {
        src:require("../img/MountainRetreatEXTA0067_design_clean_v001.png"),
        width: 4756,
        height: 1920
    },
    {
        src:require("../img/MountainRetreatEXTA0037_design_clean_v002.png"),
        width: 3196,
        height: 1836
    },
    {
        src:require("../img/LegoCaveSpiderQueenLabA0121_design_clean_v002.png"),
        width: 1920,
        height: 1083
    },
    {
        src:require("../img/LegoCaveSpiderQueenLabA0121b_design_clean_v002.png"),
        width: 1920,
        height: 1131
    },
    {
        src:require("../img/LegoCaveSpiderQueenThroneLairA0002_design_clean_v001.png"),
        width: 1920,
        height: 1080
    },
    {
        src:require("../img/LegoDesertBattleAreaA0034_design_clean_v001.png"),
        width: 1920,
        height: 572
    },
    {
        src:require("../img/LegoFFMountainMKingLivingRoomA0007_design_clean_v002.png"),
        width: 1920,
        height: 1150
    },
    {
        src:require("../img/LegoFloatingHQEXTA0011_design_clean_v001.png"),
        width: 1920,
        height: 1083
    },
    {
        src:require("../img/LegoFloatingHQEXTDeckA0071_design_clean_v001.png"),
        width: 1920,
        height: 1080
    },
    {
        src:require("../img/LegoFloatingHQEXTDeckA0082_design_clean_v001.png"),
        width: 1920,
        height: 1379
    },
    {
        src:require("../img/LegoJungleBattleAreaINTA0015_design_clean_v001.png"),
        width: 1920,
        height: 1194
    },
    {
        src:require("../img/LegoJungleBattleAreaINTA0016_design_clean_v002.png"),
        width: 1920,
        height: 1082
    },
    {
        src:require("../img/LegoJungleBattleAreaINTA0017_design_clean_v002.png"),
        width: 1920,
        height: 1079
    },
    {
        src:require("../img/LegoShadowTheatreINTA0036_design_clean_v002.png"),
        width: 1920,
        height: 1228
    },
    {
        src:require("../img/LegoShadowTheatreINTA0052_design_clean_v002.png"),
        width: 1920,
        height: 1476
    },
    {
        src:require("../img/LegoShadowTheatreINTA0056_design_clean_v001.png"),
        width: 1920,
        height: 1705
    },
    {
        src:require("../img/LegoShadowTheatreINTA0061_design_clean_v001.png"),
        width: 1920,
        height: 1080
    },
    {
        src:require("../img/LegoShadowTheatreINTA0063_design_clean_v002.png"),
        width: 1920,
        height: 744
    },
    {
        src:require("../img/LegoShadowTheatreINTA0067_design_clean_v001.png"),
        width: 1920,
        height: 2212
    },
    {
        src:require("../img/LegoShadowTheatreINTA0068_design_clean_v001.png"),
        width: 1920,
        height: 1098
    },
    //     {
    //     src:require("../img/Study 01.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/Study 02.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/Study 03.png"),
    //     title:"Journey 03",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 01.png"),
    //     title:"Journey 03",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 02.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 03.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 04.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 05.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 06.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // }
]

export default function SketchImage() {

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    let width = window.innerWidth;
    if (width > 1500){ return (
        <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
            <Gallery targetRowHeight={800} direction={"row"} photos={images} onClick={openLightbox} margin={10} limitNodeSearch={3} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal  onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={images.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );}
    else {return ( <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
        <Gallery  direction={"column"} columns={1} photos={images} onClick={openLightbox} margin={5} />
        <ModalGateway>
            {viewerIsOpen ? (
                <Modal  onClose={closeLightbox}>
                    <Carousel
                        currentIndex={currentImage}
                        views={images.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                        }))}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    </div>);}

}
