import React from 'react';
import '../App.css';
import Nav from "../component/Nav";
export default function About() {
    const navStyle={
        minWidth:"10vw",
        listStyle:"none",
        fontSize: "10pt",
        margin:0,
        backgroundColor:"white"
    }

    const liStyle={
        display:"inline",
        paddingRight:"30pt",
        lineHeight:"10pt"
    }
    const table={
        display:"table",
        margin:0,
        width:"100vw",
        paddingTop:"6vh"

    }
    const imgStyle={maxWidth:"15pt"}

    return(
        <section>
            <Nav/>
            <div style={{fontSize:"10pt"}}>
            <div style={{padding:"15vh 12% 1%"}}>Hi! I'm Brandon and I am a background artist and illustrator based in Melbourne.
                <br/>I have worked on LEGO Monkie Kid, Disney/Marvel TVA's Marvel's Moon Girl and Devil Dinosaur, Spindlehorse's Helluva Boss, and more.<br/>I like skateboarding and photography.
                <br/><br/>For freelance inquiries, please email: <span><a href={"mailto:orenji002@gmail.com"}>orenji002@gmail.com</a></span>
            </div>

            </div>
            <div style={table}>
                <ul style={navStyle}>
                    <a href={"https://www.instagram.com/orenjikun"}><li style={liStyle}><img style={imgStyle} src={require('../img/ins.png')} alt={"Instagram"}/></li></a>
                    <a href={"https://twitter.com/orenjikunart"}><li style={liStyle}><img style={imgStyle} src={require('../img/twtter.png')} alt={"twitter"}/></li></a>
                </ul>
            </div>

        </section>

    ) }
