import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import FsLightbox from 'fslightbox-react';

const images = [
    {
        src:require("../img/study 220510.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 220518.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220602.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 220613.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220628.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 220706.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220713.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220721.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220728.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 211104.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 211119.png"),
        width: 3,
        height:2
    },
    {
        src:require("../img/study 211209.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 211214.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220118.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 220125.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 220201.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 220208.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220209.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 220210.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 220214.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 220215.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220216.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 220221.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 220324.jpg"),
        width: 3012,
        height: 1080
    },
    {
        src:require("../img/study 220404.jpg"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 220411.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 220419.png"),
        width: 1,
        height: 1
    },

    {
        src:require("../img/study 210519.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 210623.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 210707.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 210721.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 210728.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/girl.png"),
        width: 1080,
        height: 1350
    },
    {
        src:require("../img/study 210811.png"),
        width: 3,
        height: 2
    },
    {
        src:require("../img/study 210818.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 210908.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 210922.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 201128.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201129.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 201115.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201116.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201104.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 201122.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 201030.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 201111.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 201117.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 201112.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 201106.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 201107.png"),
        width: 9,
        height: 16
    },
    {
        src:require("../img/study 201109.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 201110.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201114.png"),
        width: 5,
        height: 4
    },
    {
        src:require("../img/study 201026.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201027.png"),
        width: 4,
        height: 5
    },
    {
        src:require("../img/study 201105.png"),
        width: 1,
        height: 1
    },

    {
        src:require("../img/study 201023.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201025.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201108.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201019.png"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/study 201020.png"),
        width: 1,
        height: 1
    },
    {
        src:require("../img/study 201022.png"),
        width: 4,
        height: 5
    },


    //     {
    //     src:require("../img/Study 01.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/Study 02.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/Study 03.png"),
    //     title:"Journey 03",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 01.png"),
    //     title:"Journey 03",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 02.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 03.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 04.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 05.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // },{
    //     src:require("../img/BM 06.png"),
    //     title:"study",
    //     width: 64,
    //     height: 27
    // }
]

export default function SketchImage() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
            <Gallery targetRowHeight={500} direction={"row"} photos={images} onClick={openLightbox} margin={4} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={images.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}
    // const [toggler, setToggler] = useState(false);
    // const widthImage={
    //     width:"80vw",
    // }
    //
    // return (
    //     <>
    //         <div style={{cursor:"pointer"}}>
    //             <img id={"BNHA"} style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BNHA.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Kagehina.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Kageyama.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Giorno.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/KNY.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Study 01.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Study 02.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/Study 03.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BM 01.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BM 02.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BM 03.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BM 04.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)}src={require("../img/BM 05.png")} alt={"Journey"}/>
    //             <img style={widthImage}  onClick={() => setToggler(!toggler)} src={require("../img/BM 06.png")} alt={"Journey"}/>
    //         </div>
    //         <FsLightbox
    //             toggler={toggler}
    //             sources={[
    //                 require('../img/BNHA.png'),
    //                 require('../img/Journey 01.png'),
    //                 require('../img/Journey 02.png'),
    //                 require('../img/snow.png'),
    //             ]}
    //         />
    //     </>
    // );
// }
