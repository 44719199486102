import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';
import Environment from "./pages/Environment";
import Illustration from "./pages/Illustration";
import Sketch from "./pages/Sketch";
import About from "./pages/About";
import Lego from "./pages/Lego";

function App() {
  return (
      <div className={"App"}>
            <Router >
                <div>
                    <Route path="/" component={Lego} exact/>
                    <Route path='/works/environment' component={Environment} />
                    <Route path='/works/illustration' component={Illustration}/>
                    <Route path='/works/sketch' component={Sketch}/>
                    <Route path="/about" component={About}/>
                    <Route path="/works/lego" component={Lego}/>
                </div>
            </Router>

      </div>
  );
}

export default App;
