import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import FsLightbox from 'fslightbox-react';

const images = [

    {
        src:require("../img/08.png"),
        width: 2560,
        height: 1080
    },
    {
        src:require("../img/f.png"),
        width: 2560,
        height: 1080
    },
    {
        src:require("../img/k.png"),
        width: 2560,
        height: 1080
    },

    {
        src:require("../img/cny2022.png"),
        width: 1358,
        height: 1920
    },

    {
        src:require("../img/wukong.png"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/jjk.jfif"),
        width: 1,
        height: 1.4141
    },
    {
        src:require("../img/Giorno.png"),
        width: 1,
        height: 1.4141
    },
    {
        src:require("../img/Shox 03.png"),
        width: 64,
        height: 27
    },


    {
        src:require("../img/KNY.png"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/BNHA.png"),
        width: 1,
        height: 1.4141
    },

    {
        src:require("../img/knyn.jfif"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/Jolyne.png"),
        width: 1,
        height: 1.4141
    },
    {
        src:require("../img/00.png"),
        width: 1358,
        height: 1920
    },
    {
        src:require("../img/knyr.jfif"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/knyt.jfif"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/knyi.jfif"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/knyz.jfif"),
        width: 64,
        height: 27
    },
]

export default function IllustrationImage() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    let width = window.innerWidth;
    if (width > 1500){ return (
        <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
                <Gallery targetRowHeight={1000} direction={"row"} photos={images} onClick={openLightbox} margin={10} limitNodeSearch={3} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal  onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={images.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );}
    else {return ( <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
        <Gallery  direction={"column"} columns={1} photos={images} onClick={openLightbox} margin={5} />
        <ModalGateway>
            {viewerIsOpen ? (
                <Modal  onClose={closeLightbox}>
                    <Carousel
                        currentIndex={currentImage}
                        views={images.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                        }))}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    </div>);}

}
