import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import FsLightbox from 'fslightbox-react';

const images = [


    {
        src:require("..//img/streetsofaustralia_orenji.png"),
        width: 2550,
        height: 1500
    },
    {
        src:require("../img/vv2022.png"),
        width: 1376,
        height: 1920
    },


    {
        src:require("..//img/yuki7.jfif"),
        width: 4,
        height: 3
    },

    {
        src:require("..//img/rok00.png"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/beach.jfif"),
        width: 16,
        height: 9
    },
    {
        src:require("../img/FXgAX8OagAACUHE.jpg"),
        width: 1376,
        height: 1920
    },
    {
        src:require("..//img/odd1.jfif"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/odd2.jfif"),
        width: 64,
        height: 27
    },

    {
        src:require("..//img/odd3.jpg"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/odd5.jpg"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/sm00.png"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/arc00.png"),
        width: 64,
        height: 27
    },
    {
        src:require("..//img/sg.jfif"),
        width: 16,
        height: 9
    },

    {
        src:require("../img/Journey 02.png"),
        width: 64,
        height: 27
    },
    {
        src:require("../img/Kagehina.png"),
        width: 64,
        height: 27
    },

    ]

export default function EnvironmentImage() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    let width = window.innerWidth;
    if (width > 1500){
        return (
        <div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
            <Gallery  targetRowHeight={1000} direction={"row"} photos={images} onClick={openLightbox} margin={10} limitNodeSearch={2}/>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={images.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}
    else {return (<div style={{paddingTop:"2%",width:"80vw",margin:"0 auto"}}>
        <Gallery direction={"column"} columns={1} photos={images} onClick={openLightbox} margin={5}/>
        <ModalGateway>
            {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                        currentIndex={currentImage}
                        views={images.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title,
                        }))}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    </div>);}}
//     const [toggler, setToggler] = useState(false);
//     const widthImage={
//         width:"80vw"
//     }
//
//     return (
//         <>
//             <div style={{cursor:"pointer"}}>
//                 <img style={widthImage}  onClick={() => setToggler(!toggler)} src={require("../img/wukong.png")} alt={"Journey"}/>
//                 <img style={widthImage} onClick={() => setToggler(!toggler)} src={require("../img/Journey 01.png")} />
//                 <img style={widthImage}  onClick={() => setToggler(!toggler)} src={require("../img/Journey 02.png")} alt={"Journey"}/>
//                 <img style={widthImage}  onClick={() => setToggler(!toggler)} src={require("../img/snow.png")} alt={"Journey"}/>
//             </div>
//             <FsLightbox
//                 toggler={toggler}
//                 sources={[
//                     require('../img/wukong.png'),
//                     require('../img/Journey 01.png'),
//                     require('../img/Journey 02.png'),
//                     require('../img/snow.png'),
//                 ]}
//             />
//         </>
//     );
// }
